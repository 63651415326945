<template>
  <div>
    <!-- form 表单 -->
    <el-col :span="11" class="demo-form-box">
      <el-form :model="certifications[index]" label-width="40px">
        <el-row>
          <el-col :span="24" class="removeBox"><i class="el-icon-close" v-show="certifications[index].hasModify"
                                                  @click="removeFromBox()"></i></el-col>

          <el-col :span="24">
            <el-form-item label="名称">
              <el-input v-if="certifications[index].hasModify" v-model="certifications[index].name"
                        placeholder=""></el-input>
              <span v-if="!certifications[index].hasModify">{{ certifications[index].name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="certifications[index].download">
            <a :href="certifications[index].download" style="font-size: 12px">{{certifications[index].filename}}</a>
          </el-col>
          <el-col :span="24">
            <el-form-item label="说明">
              <span v-html="certifications[index].brief"></span>
              <span v-html="certifications[index].required" style="margin-left: 10px"></span>
              <!--<el-input v-if="certifications[index].hasModify" v-html="certifications[index].brief"
                        placeholder=""></el-input>-->
              <!--<span v-if="!certifications[index].hasModify">{{ certifications[index].brief }}</span>-->
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-upload
              class="upload-demo"
              ref="upload"
              :action="`${this.getaway.redirect}srm/file/upload`"
              :file-list="certifications[index].fileList"
              :auto-upload="true"
              :multiple="true"
              :on-remove="handleRemove"
              :on-success="handleSuccess">
              <el-button slot="trigger" size="small">选取文件</el-button>
            </el-upload>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
  </div>
</template>
<script>
export default {
  name: 'certification',
  props: ['certifications', 'index'],
  data () {
    return {
      rules: {},
      dialogImageUrl: '',
      fileList: [],
      dialogVisible: false
    }
  },
  mounted () {
  },
  methods: {
    // 通过索引删除一位
    removeFromBox () {
      if (this.certifications.length > 1) {
        this.certifications.splice(this.index, 1)
      } else if (this.certifications.length === 1) {
        this.$message({
          message: '至少添加一种资质!',
          type: 'error'
        })
      }
    },

    // 上传之后返回的数据
    handleSuccess (response, file, fileList) {
      let arr = []
      const md5 = response.data.md5
      fileList.forEach(f => {
        arr.push({
          name: f.name,
          md5: md5
        })
      })
      this.certifications[this.index].fileList = arr
      this.certifications[this.index].md5.push(md5)
    },

    handleRemove (file, fileList) {
      const md5 = file.response.data.md5
      let arr = []
      fileList.forEach(f => {
        arr.push({
          name: f.name,
          md5: md5
        })
      })
      this.certifications[this.index].fileList = arr
      this.certifications[this.index].md5.remove(md5)
    }
  }
}
</script>

<style lang="less" scoped>
.demo-form-box {
  border: 1px solid #ccc;
  padding: 10px 30px 0px 30px;
  border-radius: 20px;
  margin: 10px;
  height: 278px;
  box-sizing: border-box;
  overflow: auto;
}

.upload-demo {
  display: inline-block;
  // width: 25%;
  margin-left: 5%;
}

.removeBox {
  height: 40px;
  position: relative;

  i {
    position: absolute;
    top: 10px;
    right: 0px;
  }
}
</style>
